import { render, staticRenderFns } from "./aggrid-neraca.vue?vue&type=template&id=37b6ad46&scoped=true&"
import script from "./aggrid-neraca.vue?vue&type=script&lang=js&"
export * from "./aggrid-neraca.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b6ad46",
  null
  
)

export default component.exports