<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-modal v-model="visiblemodal" :width="800" :dialogStyle="{ top: '10px' }" title="Form Pengesahan">
        <template slot="footer">
          <!-- <a-button-group class="ml-2"> -->
            <!-- <a-tooltip placement="top">
              <template slot="title">
                <span>Close this Magic</span>
              </template>
              <a-button class="text-danger" @click="visiblemodal = !visiblemodal"><a-icon type="cross"></a-icon></a-button>
            </a-tooltip> -->
            <!-- <a-tooltip placement="top">
              <template slot="title">
                <span>Saved Changes You've Been Doing!</span>
              </template> -->
              <a-button class="btn btn-primary" @click="simpan">
                Simpan</a-button
              >
            <!-- </a-tooltip> -->
          <!-- </a-button-group> -->
        </template>
        <div class="row" style="padding: 25px">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3"></div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><strong>1</strong></center>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><strong>2</strong></center>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <center><strong>3</strong></center>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <strong>Atibute</strong>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Pembuat"
              v-model="input.paraf_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Pemeriksa"
              v-model="input.paraf_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Mengetahui"
              v-model="input.paraf_3"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <strong>Nama</strong>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Alfi Sakdiyah"
              v-model="input.nama_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: NURLAILA"
              v-model="input.nama_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: MUHAMMAD YAZID"
              v-model="input.nama_3"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
            <strong>Jabatan</strong>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Teller"
              v-model="input.jabatan_1"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Wakil Manajer"
              v-model="input.jabatan_2"
            ></a-input>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
            <a-input
              style="text-align: center"
              class=""
              placeholder="Ex: Manajer"
              v-model="input.jabatan_3"
            ></a-input>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
export default {
  created() {
    this.getSettings()
  },
  data() {
    return {
      visiblemodal: false,
      input: {
        paraf_1: '',
        nama_1: '',
        jabatan_1: '',
        paraf_2: '',
        nama_2: '',
        jabatan_2: '',
        paraf_3: '',
        nama_3: '',
        jabatan_3: '',
      },
      response: null,
    }
  },
  methods: {
    moment,
    showModal() {
      this.visiblemodal = true
      // this.getSettings()
    },
    // async getSettings() {
    //   var res = await lou.customUrlGet2('transaksi/simpanan/pengesahan')
    //   this.input = res.data
    //   // this.input.periode = moment(res.data.periode, 'YYYY-MM-DD')
    //   // console.log('this.input', this.input)
    //   // this.response = this.$g.clone(res.data)
    // },
    async simpan() {
      var fd = this.input
      // fd.periode = this.input.periode.format('YYYY-MM-DD')
      // var res =
      var res = await lou.customUrlPost2('general/master/2/pengesahan', fd)
      if (res) {
        // this.getSettings()
        this.visiblemodal = !this.visiblemodal
        // this.$store.commit('user/SET_STATE', {
        // periode: this.input.periode.format('YYYY-MM-DD'),
        // })
        // localStorage.setItem(
        //   'userData',
        //   JSON.stringify(this.$store.state.user),
        // )
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped></style>
